import React, { useState } from "react"
import ReactMarkdown from "react-markdown"
import { graphql } from "gatsby"
import queryString from "query-string"
import { isNil, isEmpty } from "ramda"

import { Layout } from "../components"

import styles from "./contact.module.css"

export default ({ data, ...props }) => {
  const queryParams = queryString.parse(props.location.search)
  const submitted = queryParams.submit === "true"

  const [formValues, setFormValues] = useState({
    eeeeeeee: "", // email
    sssssssss: "", // subject
    mmmmmmmm: "" // message
  })
  const [showErrors, setShowErrors] = useState(false)

  const validate = () => {
    let isFormValid = true

    if (isNil(formValues.eeeeeeee) || isEmpty(formValues.eeeeeeee)) {
      isFormValid = false
    }

    if (isNil(formValues.sssssssss) || isEmpty(formValues.sssssssss)) {
      isFormValid = false
    }

    if (isNil(formValues.mmmmmmmm) || isEmpty(formValues.mmmmmmmm)) {
      isFormValid = false
    }

    return isFormValid
  }

  const handleSubmit = (event) => {
    const result = validate() // do this on every form change

    if (!result) {
      event.preventDefault()
      setShowErrors(true)
      return
    }

    setShowErrors(false)
  }

  const handleFormChange = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value })
  }

  return (
    <Layout>
      <div style={{ marginLeft: "auto", marginRight: "auto", maxWidth: 800 }}>
        <ReactMarkdown
          source={data.allContentfulPage.edges[0].node.text.text}
        />

        {submitted && <div className={styles.submitted}>Message sent!</div>}

        {!submitted && (
          <form action="mail.php" method="POST" onSubmit={handleSubmit}>
            {/* Real fields */}
            <div>Email:</div>{" "}
            <input
              onChange={handleFormChange}
              type="text"
              name="eeeeeeee"
              value={formValues.eeeeeeee}
            />
            <br />
            <br />
            <div>Subject:</div>{" "}
            <input
              onChange={handleFormChange}
              value={formValues.sssssssss}
              type="text"
              name="sssssssss"
            />
            <br />
            <br />
            <div>Your message:</div>
            <textarea
              onChange={handleFormChange}
              value={formValues.mmmmmmmm}
              name="mmmmmmmm"
              rows="6"
              cols="25"
            ></textarea>
            <br />
            <br />
            {/* Guard to catch spam*/}
            <label className={styles.guard} htmlFor="email"></label>
            <input
              className={styles.guard}
              autoComplete="off"
              type="email"
              id="email"
              name="email"
              placeholder="Your e-mail here"
            />
            <label className={styles.guard} htmlFor="subject"></label>
            <input
              className={styles.guard}
              autoComplete="off"
              type="subject"
              id="subject"
              name="subject"
              placeholder="Your e-mail here"
            />
            <label className={styles.guard} htmlFor="message"></label>
            <input
              className={styles.guard}
              autoComplete="off"
              type="message"
              id="message"
              name="message"
              placeholder="Your e-mail here"
            />
            {showErrors && (
              <div className={styles.warning}>
                Please fill out all form fields!
              </div>
            )}
            <input className={styles.sendButton} type="submit" value="Send" />
          </form>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulPage(
      filter: { contentful_id: { eq: "3jEJtQsCocmQl3Q5wPJtQi" } }
    ) {
      edges {
        node {
          title
          text {
            text
          }
        }
      }
    }
  }
`
